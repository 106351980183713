import OnScreen from "../hooks/OnScreen";

const STEAMagine2025 = () => {
  return (
    <>
      <OnScreen>
        <section className="section">
          <div className="wrapper small">
            <div className="section-title">
              <h2 className="title-alt">STEAMagine 2 ou l’école du futur</h2>
            </div>
            <p className="chapo">
              “STEAMagine 2 l’école du futur” est un projet d’intelligence
              collective destiné à rendre autonomes les enseignants dans la mise
              en œuvre d’activités STEAM et numériques dans leur pratique
              pédagogique.
            </p>
            <p className="text">
              Le programme, désormais plus ciblé, s’appuie sur une synergie
              renforcée entre partenaires avec la volonté de toucher les
              enseignants du tronc commun de la P5 à la S3.
            </p>
            <div className="align-center">
              <img src="img/steamagine2.png" alt="STEAMagine" />
            </div>

            <p className="title-cat">
              Le plan d’actions se déploie selon 3 axes :
            </p>
            <div className="container col-3">
              <p className="text-col">
                <p className="col-title">Axe formation</p>
                Cet axe mobilise une communauté de pratique pour catalyser les
                initiatives individuelles et collectives. Il propose ensuite des
                Créacamps au cours desquels les enseignants participants
                partagent leurs expériences et meilleures pratiques. Enfin, ces
                participants bénéficient d’un support via un système de
                co-enseignement pour faciliter l’application et le transfert en
                classe.
              </p>
              <p className="text-col">
                <p className="col-title">Axe sensibilisation</p>
                Cet axe intègre la création d’une chaîne de webinaires STEAM
                accessibles aux enseignants, et un projet-pilote de formation
                d’influenceurs dans les domaines des sciences et technologies,
                visant à répondre à un besoin souvent évoqué sur les dispositifs
                d’influence.
              </p>
              <p className="text-col">
                <p className="col-title">
                  Axe recherche, développement et évaluation
                </p>
                Sous la direction de l’UMONS, cet axe se concentre sur le suivi
                et l’évaluation scientifique du projet, mais surtout sur le
                développement de badges en lien avec le référentiel STEAM
                proposé par STEAMuli et de valoriser les compétences acquises ou
                les réalisations des enseignants.
              </p>
            </div>
            <div className="container col-2 margin-top">
              <img
                className="img-full"
                src="img/steamagine-5.jpg"
                alt="STEAMagine"
              />
              <img
                className="img-full"
                src="img/steamagine-10.jpg"
                alt="STEAMagine"
              />
            </div>
            <div className="container col-2">
              <img
                className="img-full"
                src="img/steamagine-41.jpg"
                alt="STEAMagine"
              />
              <img
                className="img-full"
                src="img/steamagine-43.jpg"
                alt="STEAMagine"
              />
            </div>
            <p className="title-bis">Partenariat</p>
            <p className="text">
              Cette nouvelle édition de STEAMagine resserre les synergies entre
              partenaires (Technofutur TIC, l’UMONS, Kodo Wallonie et
              l’Eurometropolitan e-Campus) amplifiant une collaboration bien
              établie en multi-projets, tant en ingénierie de formation, en
              design d’activités, en modalités pédagogiques innovantes, en
              communication.
            </p>
            <p className="text">
              Ce partenariat consolidé apporte une valeur ajoutée significative
              au projet, en mettant un accent particulier sur l’engagement des
              enseignants, crucial sur le projet. Cette collaboration a déjà
              porté ses fruits dans des initiatives antérieures telles que le
              certificat universitaire en techno-pédagogie NUMEFA ou des projets
              FSE.
            </p>
            <p className="text">
              La reconfiguration permet aussi d’optimiser la couverture
              territoriale de régions moins représentées dans les éditions
              précédentes, comme la Wallonie picarde. L’intégration de
              l’Eurometropolitan e-Campus permet ainsi de créer un projet actif
              sur l’ensemble du territoire grâce à ses infrastructures de
              proximité. Ce maillage territorial renforcé assure une diffusion
              plus vaste et inclusive des initiatives STEAM.
            </p>
            <p className="text">
              Sur le plan méthodologique, cette alliance renforcée embrasse une
              vision commune d’intelligence collective, alignée sur les
              principes des « Territoires apprenants » et les objectifs de
              développement durable de l’UNESCO.
            </p>
            <div className="container col-2 margin-top">
              <img
                className="img-full"
                src="img/steamagine-1.jpg"
                alt="STEAMagine"
              />
              <img
                className="img-full"
                src="img/steamagine-4.jpg"
                alt="STEAMagine"
              />
            </div>
            <p className="title-bis">Activités</p>
            <p className="text">
              Les différentes activités du projet STEAMagine 2 se dérouleront de
              janvier 2025 à août 2026.
            </p>
            <p className="title-cat">a. Communauté de pratique</p>
            <p className="text">
              Dans le cadre du projet STEAMagine 2 l’école du futur, une
              communauté de pratique (COP) sera mise en place afin d’offrir une
              plateforme d’échanges de connaissances, de témoignages en lien
              avec le numérique et l’approche STEAM.
            </p>
            <p className="text">
              La COP offrira un espace d’incubation, où les participants
              pourront développer des initiatives individuelles ou
              collaboratives, s’appuyer sur un réseau riche de ressources et de
              soutien et profiter des expériences de plusieurs experts,
              notamment grâce aux webinaires.
            </p>
            <p className="text">
              Les interactions au sein de cette communauté stimuleront
              l’innovation, encourageront la résolution de problèmes et
              favoriseront un environnement d’apprentissage actif et
              participatif.
            </p>
            <p className="title-cat">b. Webinaires</p>
            <p className="text">
              Les webinaires offrent aux enseignants du tronc commun un accès à
              des expertises et des ressources pour intégrer les STEAM et le
              numérique en classe. À travers ces sessions interactives, les
              participants peuvent découvrir des outils innovants, partager des
              bonnes pratiques et échanger avec des experts. Ces webinaires
              favorisent la collaboration, l’innovation pédagogique et mettent
              en avant des projets STEAMS déployés dans les écoles. Disponibles
              en replay, ils accompagnent les enseignants dans la mise en œuvre
              de projets concrets.
            </p>
            <p className="title-cat">c. CréaCamps</p>
            <p className="text">
              Des journées de formations immersives qui offrent une série
              d’ateliers diversifiés et pratiques, conçus pour explorer des
              concepts et des outils STEAM de manière concrète avec comme
              finalités de co-créer des ressources, des activités, des outils en
              lien avec les STEAM et de partager, d’échanger sur ses pratiques.
            </p>
            <p className="title-cat">d. Co-enseignement</p>
            <p className="text">
              Les enseignants intéressés pourront également faire appel à l’asbl
              Kodo Wallonie pour réaliser des activités STEAM en coenseignement
              dans leur classe. De la préparation au suivi post-activité,
              l’équipe de Kodo Wallonie sera présente et soutenante pour
              réaliser des ateliers pertinents suivant l’âge de vos apprenants
              et vos possibilités.
            </p>
            <p className="title-cat">e. Formation influenceurs</p>
            <p className="text">
              Cette formation pilote a pour objectif d’accompagner les
              enseignants dans la création de contenus scientifiques et
              technologiques adaptés aux formats des réseaux sociaux actuels.
              Structurée en modules pratiques, elle couvre la maîtrise de la
              création de contenu numérique, la compréhension de la didactique
              scientifique, la production vidéo, ainsi que la gestion de
              communautés en ligne.
            </p>
            <p className="title-cat">f. Écosystème d’Open Badges</p>
            <p className="text">
              Le projet STEAMagine l’école du futur a rejoint la coupole
              STEAMULI afin de mutualiser la reconnaissance des compétences, des
              apprentissages et des réalisations via les Open Badges. L’équipe
              STEAMagine développera un écosystème d’Open Badges conforme au
              référentiel établi par STEAMULI pour mettre en avant les
              initiatives STEAM des enseignants.
            </p>
            <p className="title-bis">Partenaires</p>
            <div className="container col-2">
              <a
                href="https://edu-lab.be/"
                target="_blank"
                rel="noreferrer"
                className="rnig04d673 g200mv1sa1 support"
              >
                <img src="img/edulab.png" alt="edulab" />
              </a>
              <a
                href="https://web.umons.ac.be/fr/"
                target="_blank"
                rel="noreferrer"
                className="rnig04d673 g200mv1sa1 support"
              >
                <img src="img/umons.png" alt="umons" />
              </a>
            </div>
            <div className="container col-2">
              <a
                href="https://kodowallonie.be/"
                target="_blank"
                rel="noreferrer"
                className="rnig04d673 g200mv1sa1 support"
              >
                <img src="img/kodo.png" alt="kodo" />
              </a>
              <a
                href="https://ee-campus.be/"
                target="_blank"
                rel="noreferrer"
                className="rnig04d673 g200mv1sa1 support"
              >
                <img src="img/e-campus.png" alt="ecampus" />
              </a>
            </div>
            <div className="wrapper-soutien">
              <img className="soutien" src="img/soutien.png" alt="soutien" />
            </div>
            <div className="wrapper-soutien">
              <img className="soutien" src="svg/relance.svg" alt="soutien" />
            </div>
          </div>
        </section>
      </OnScreen>
    </>
  );
};

export default STEAMagine2025;
